import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignOut } from '@aws-amplify/ui-react';
// import { HashRouter, Route, Link, Switch } from 'react-router-dom';
import {Auth , Hub } from 'aws-amplify';
import { Layout, Row, Col} from 'antd';

import { AppContext } from "./libs/contextLib";
import Routes from "./Routes";
// import VirtualMaterials from './VirtualMaterials';
import MundopatoFooter from './MundopatoFooter';
import * as security from './Security';

import "antd/dist/antd.css";
import './App.css';

const { Header, Content } = Layout;
const vmheader = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  padding: '20px 0 0 5px',
  backgroundColor: 'rgba(222, 233, 237, 0.5)',
  height: 'auto',
  marginBottom: '10px',
};

const cssBody = {
  /*display: 'flex',
  flexDirection: 'column',*/
  height: '100vh',
}

const cssLogo = {
  maxWidth: '320px'
}


function App() {
  const history = useHistory();
  const [user, updateUser] = React.useState(null);
  const [isLinked, updateIsLinked] = useState(0);
  const [clientCode, setClientCode] = useState(null);
  
  useEffect(() => {
    checkUser();
  }, []);

  /**
  */ 
  async function checkUser() {
    console.log("checking user");
    const user = await Auth.currentAuthenticatedUser();
    updateUser(user);
    if(user) {
      try {
      console.log("login..");
      let success = false;
        success = await security.login();
        if(success) {
          const medialib = security.getMediaLibrary();
          if(medialib.ctx.orgid > 0 && medialib.ctx.userid > 0) {
            //is linked!
            console.log("is linked! go to assignments");
            updateIsLinked(1);
            history.push('/assignments');
          } else {
            //is not linked
            console.log("is not linked! go to link");
            updateIsLinked(2);
            history.push('/link');
          } //means we need to capture meta info
            // updateIsLinked(4);
        }
      } catch(err) {
        console.error(err);
      }
    } else {
      console.log("not user?");
    }
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => updateUser(user))
      .catch(() => console.log('No signed in user.'));

    Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signIn':
          return updateUser(data.payload.data);
        case 'signOut':
          return updateUser(null);
      }
    });
  }, []);


  if (user) {
    return (
<Layout style={cssBody}>
  <Header style={vmheader}>
    <img src='/logomp.png' style={cssLogo}></img>          
    <h2>Welcome, { user && user.username }</h2>
    <AmplifySignOut />
  </Header>
  <Content>

    <AppContext.Provider value={{ user, updateUser, 
      isLinked, updateIsLinked,
      clientCode, setClientCode
    }}>
        <Routes />
      </AppContext.Provider>

  </Content>
  <MundopatoFooter/>
</Layout>
    )
  }
  else {
    return (
      <div>
       <div style={vmheader}>
         <img src='/logomp.png' style={cssLogo}></img>          
          <h2>Virtual Materials App</h2>
       </div>
      <AmplifyAuthenticator>
      <AmplifySignUp/>
      </AmplifyAuthenticator>
      <MundopatoFooter/>
      </div>
    )
  }
}

export default App;
