import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./containers/NotFound";
import Home from "./containers/Home";
import Link from "./containers/Link";
import Assignments from "./containers/Assignments";
import Assignment from "./containers/Assignment";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
        </Route>
        <UnauthenticatedRoute exact path="/signup">
          <div>Sign Up</div>
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/login">
          <div>Login </div>
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/assignments">
        <Assignments />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/assignment">
        <Assignment />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/link">
        <Link />
      </AuthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
