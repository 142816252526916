const Mundopato = {};
  Mundopato.Medialibrary = {
    VERSION: 20200217.03,
    envs: {
      "local": {
          url : "http://localhost:3000/",
          assets : "http://localhost:3000/s3/local-medialibrary/"
        },
      "QA" :{
          url : "https://p75uvcjgq8.execute-api.us-west-2.amazonaws.com/QA/",
          assets : "https://s3-us-west-2.amazonaws.com/test-medialibrary/"
        },
      "PHQA" :{
          url : "https://p75uvcjgq8.execute-api.us-west-2.amazonaws.com/QA/",
          assets : "https://s3-us-west-2.amazonaws.com/test-medialibrary/"
        },
      "US":{
        url : "https://p75uvcjgq8.execute-api.us-west-2.amazonaws.com/PROD/",
        assets : "https://s3-us-west-2.amazonaws.com/prod-unitus-medialibrary/"
        },
      "PHUS":{
        url : "https://p75uvcjgq8.execute-api.us-west-2.amazonaws.com/PROD/",
        assets : "https://s3-us-west-2.amazonaws.com/prod-unitus-medialibrary/"
        },
      "CA":{
        url : "https://p75uvcjgq8.execute-api.us-west-2.amazonaws.com/PROD/",
        assets : "https://s3-us-west-2.amazonaws.com/prod-unitus-medialibrary/"
      },
      "HR":{
        url : "https://p75uvcjgq8.execute-api.us-west-2.amazonaws.com/PROD/",
        assets : "https://s3-us-west-2.amazonaws.com/prod-unitus-medialibrary/"
      },
      "IM": {
        url : "https://p75uvcjgq8.execute-api.us-west-2.amazonaws.com/PROD/",
        assets : "https://s3-us-west-2.amazonaws.com/prod-unitus-medialibrary/"
      }
    }
  }
/**
 *
 */ 
Mundopato.Medialibrary.APIError = class extends Error {
  constructor(name, errcode)
  {
    super(name)
    this.code = errcode;
    this.details = [];
  }

  /**
   * push a detail along with a timestamp
  */ 
  addDetail(msg){
    this.details.push({timestamp: Date.now(), msg: msg});
  }
}

 /**
 *
 */ 
Mundopato.Medialibrary.API = class {

  /**
   * MediaLibraryAPI:constructor
   */ 
  constructor()
  {
    this.ctx = null;
    this.env = null;
  }

  /**
   * MediaLibraryAPI:init
   * important! 
   * previous version requires different api and unitusapi parameters,
   * now we relay only in unitusapi (called 'env') to decide
   * internally what medialibrary api endpoint to use. 
   * @param env
   * @param env local, QA, CA, US
   * @param token
   * @param material optional, allows access to some material-only services.
   * @param platform = optional, defaults to "unitusti", "cognito" for aws auth.
   */ 
  async init({env, token, material, platform})
  {
    if (!Mundopato.Medialibrary.envs[env]) {
      throw new Error(`MediaLibraryAPI: unsupported ENV: "${env}". build: ${Mundopato.Medialibrary.VERSION} `);
    }
    if (!platform) {
      platform = 'unitusti';
    }
    if(!['unitusti', 'cognito', 'vmapp'].includes(platform)) {
      throw new Error(`MediaLibraryAPI: unsupported Platform: "${platform}". build: ${Mundopato.Medialibrary.VERSION} `);
    }
    this.env = env; 
    let url = this.buildUrl("auth");
    let body = {
      material,
      platform,
    }
    if ( platform === 'unitusti' ) {
      body.unitus_api= env;
      body.unitus_token= token;
    } else if (['cognito', 'vmapp'].includes(platform)) {
      body.cognito_token= token;
    }
    let data = await this.send(url, "POST", body);
    this.ctx = data;
  }

  /**
   * MediaLibraryAPI:login
  */ 
  async login({env, orgid, name, password, material}){
    if (!Mundopato.Medialibrary.envs[env]) {
      throw new Error(`MediaLibraryAPI: unsupported ENV: "${env}". build: ${Mundopato.Medialibrary.VERSION} `);
    }
    this.env = env;
    let url = this.buildUrl('auth');
    let body = {
      env,
      orgid,
      name,
      password,
      material
    };
    let data = await this.send(url, "POST", body);
    this.ctx = data;
  }

  /**
   * MediaLibraryAPI:send
   * fetch a URL with given method and optional headers and body.
   * returns json parsed response
   */ 
  async send(url, method, body, headers) {
    let data = null;
    if(!headers){
      headers = {};
    }
    headers["content-Type"] =  "application/json";
    if(this.ctx && this.ctx.token) {
      headers.authtoken = this.ctx.token;
    }
    let response = await fetch(url, {
      method: method,
      mode: "cors",
      cache: "no-cache",
      headers: headers,
      body: (body?JSON.stringify(body):null) 
      }).then(response => {
        if (response.status >= 400 && response.status < 600) 
        {
          let error = new Mundopato.Medialibrary.APIError(`MediaLibrary API Error: ${response.status}`, response.status);
          error.url = url; 
          throw error;
        }
        return response; 
      }).then(returnedResponse => {
          data = returnedResponse.json();
      }).catch((networkerror) => {
        if(networkerror instanceof Mundopato.Medialibrary.APIError)
        {
          //just re-throws
          throw networkerror;
        }
        else if(networkerror.name === 'TypeError')
        {
          //transient network error or CORS issue
          throw new Mundopato.Medialibrary.APIError("network error", 0);
        }
        else
        {
          //wrap into MediaLibraryAPIError
          throw new Mundopato.Medialibrary.APIError("network error", 0);
        }
      });
      return data;
  }

  /**
   * MediaLibraryAPI:getPictureURL
   * @param picture {Object with s3key_path and update_time property}
   */ 
  getPictureURL(picture)
  {
    return this.getAssetsURL() + picture.s3key_path + "?t="+picture.update_time;
  }
  
  /**
   * MediaLibraryAPI:getPictureURL
   * @param picture {Object with pic_s3key_path and update_time property}
   */ 
  getPicturePicURL(picture)
  {
    return this.getAssetsURL() + picture.pic_s3key_path + "?t="+picture.update_time;
  }
  
  /**
   * MediaLibraryAPI:getTrackURL
   * @param track {Object with s3key}
   */ 
  getTrackURL(track)
  {
    return this.getAssetsURL() +"tracks/" + track.s3key
  }

  /*
   * returns the base url to query assets
  */ 
  getAssetsURL(){
    return Mundopato.Medialibrary.envs[this.env].assets;
  }

  /**
   * MediaLibraryAPI:url
   * @param endpoint {string} endpoint to hit (without start with /)
   * will be prepended by the API base path, based on env.
   * @params {object} will be used as GET parameters, if present
   */ 
  buildUrl(endpoint, params) {
    let base = Mundopato.Medialibrary.envs[this.env].url;
    let query = "";
    if(params && Object.keys(params).length) {
      query = "?";
      for (let param in params) {
        if (query.length > 1){
         query += "&";
        }
        let val = params[param];
        if (val === undefined || val === null) {
          val = '';
        }
        query += `${escape(param)}=${escape(val)}`;
        
      }
    }
    return base + endpoint + query; 
  }

  /**
   * MediaLibraryAPI:getTags
   * @param filter {string}
   * @param domain {string} 'packs' by now, expected to support 'activities'
   *
   * @returns {object}: there are two arrays of tags: global and tags.
   * 'global' by default includes the tags in the mundopato org. 
   * 'tags' include tags that belongs only to current org.
   * {
   *   global: [ 
   *     {tag, }
   *   ]
   *   tags: [
   *    {tag }
   *   ]
   * }
   */ 
  async getTags(filter, domain) {
    let data = await this.send(this.buildUrl("tags", {filter, domain}), "GET")
    return data;
  } 

  /**
   * MediaLibraryAPI:createTag
   */ 
  async createTag(tag)
  {
    let data = await this.send(this.buildUrl("tags"), "POST", {tag: tag});
    return data;
  }
  
  /**
   * MediaLibraryAPI:deleteTag
   * @param tag is a string. we assume orgid is the same as user.
   */ 
  async deleteTag(tag)
  {
    await this.send(this.buildUrl("tags"), "delete", {tag: tag});
  }

  /**
   * MediaLibraryAPI:getAppliedTags
   */ 
  async getAppliedTags(category, id)
  {
    let data = await this.send(this.buildUrl("atags", {category, id}), "GET")
    return data
  }

  /**
   * MediaLibraryAPI:updateAppliedTags
   */ 
  async updateAppliedTags(category, id, tags)
  {
    let data = await this.send(this.buildUrl("atags"), "PUT", {category, id, tags});
    return data;
  }

  /**
   * MediaLibraryAPI:searchPictures
   */ 
  async searchPictures(name, tags)
  {
    let params = {
      filter: tags.map(tag => tag.tag).join(","),
      name: name
    }
    let data = await this.send(this.buildUrl("pictures/search", params), "GET") 
    return data;
  }

  /**
   * MediaLibraryAPI:getPictures
   * return pictures based on array of pictures ids
   */ 
  async getPictures(ids)
  {
    if(!ids.length) return [];
    let params = {
      ids: ids.join(",")
    }
    let data = await this.send(this.buildUrl("pictures", params), "GET") 
    return data;
  }

  /**
   *
          name: _getVal(this.$input_name, true),
          license:_getVal(this.$input_license),
          url: _getVal(this.$input_url),
          width: canvas.width,
          height: canvas.height,
          data: canvas.toDataURL(),
          tags: []
   */ 
  async createPicture({name, license, url, width, height, data, tags})
  {
    return await this.send(this.buildUrl("pictures"), "POST", {name, license, url, width, height, data, tags})
  }

  /**
   * MediaLibraryAPI:deletePicture
   */ 
  async deletePicture(id)
  {
    return await this.send(this.buildUrl("pictures"), "DELETE",{id});
  }

  /**
   * MediaLibraryAPI:updatePicture
   */ 
  async updatePicture(picturedata)
  {
    return await this.send(this.buildUrl("pictures"), "PUT", picturedata);
  }



  /**
   * MediaLibraryAPI:createPose
   * will create a pose in "draft" status
   */ 
  async createPose({name, config, tags})
  {
    return await this.send(this.buildUrl("poses"), "POST", {name, config, tags})
  }


  /**
   * MediaLibraryAPI:updatePose
   */ 
  async updatePose({idpose, name, status, config, tags})
  {
    return await this.send(this.buildUrl("poses"), "PUT", {idpose, name, status, config, tags})
  }
  
  /**
   * MediaLibraryAPI:deletePose
   */ 
  async deletePose(idpose)
  {
    return await this.send(this.buildUrl("poses"), "DELETE",{idpose})
  }

  /**
   * MediaLibraryAPI:searchPoses
   */ 
  async searchPoses({name, tags, status, scope})
  {
    let params = {
      tags: tags.map(tag => tag.tag).join(","),
      name: name,
      status: status,
      scope: scope
    }
    let data = await this.send(this.buildUrl("poses", params), "GET") 
    return data;
  }

  /**
   * MediaLibraryAPI:getPoses
   * return poses based on array of poses ids
   */ 
  async getPoses(ids)
  {
    let params = {
      ids: ids.join(",")
    }
    let data = await this.send(this.buildUrl("poses", params), "GET") 
    return data;
  }

  /**
   * MediaLibraryAPI:getPose
   * return pose based on pose id
   */ 
  async getPose(id)
  {
    let params = {
      id: id
    }
    let data = await this.send(this.buildUrl("poses", params), "GET") 
    return data
  }


  /**
   * MediaLibraryAPI:createTarget
   * will create a activity in "draft" status
   */ 
  async createTarget({name, description, config, tags})
  {
    return await this.send(this.buildUrl("targets"), "POST", {name, description, config, tags});
  }

  /**
   * MediaLibraryAPI:createActivity
   * will create a activity in "draft" status
   */ 
  async createActivity({name, author, description, config, tags})
  {
    return await this.send(this.buildUrl("activities"), "POST", {name, author, description, config, tags});
  }


  /**
   * MediaLibraryAPI:updateActivity
   */ 
  async updateActivity({idactivity, name, author, description, status, config, tags})
  {
    return await this.send(this.buildUrl("activities"), "PUT", {idactivity, name, author, description, status, config, tags})
  }

  /**
   * MediaLibraryAPI:deleteActivity
   */ 
  async deleteActivity(idactivity)
  {
    return await this.send(this.buildUrl("activities"), "DELETE",{idactivity})
  }

  /**
   * MediaLibraryAPI:searchActivities
   */ 
  async searchActivities({name, tags, status, scope})
  {
    let params = {
      tags: tags.map(tag => tag.tag).join(","),
      name: name,
      status: status,
      scope: scope
    }
    let data = await this.send(this.buildUrl("activities", params), "GET") 
    return data;
  }

  /**
   * MediaLibraryAPI:getActivities
   * return activities based on array of activities ids
   */ 
  async getActivities(ids)
  {
    let params = {
      ids: ids.join(",")
    }
    let data = await this.send(this.buildUrl("activities", params), "GET") 
    return data;
  }

  /**
   * MediaLibraryAPI:getActivity
   * return activity based on activity id
   */ 
  async getActivity(id)
  {
    let params = {
      id: id
    };
    let data = await this.send(this.buildUrl("activities", params), "GET");
    return data;
  }

  /**
   * MediaLibraryAPI:createSong
   * will create a song in "draft" status
   */ 
  async createSong({name, author, description, config, tags})
  {
    return await this.send(this.buildUrl("songs"), "POST", {name, author, description, config, tags})
  }


  /**
   * MediaLibraryAPI:updateSong
   */ 
  async updateSong({idsong, name, author, description, status, config, tags})
  {
    return await this.send(this.buildUrl("songs"), "PUT", {idsong, name, author, description, status, config, tags})
  }

  /**
   * MediaLibraryAPI:deleteSong
   */ 
  async deleteSong(idsong)
  {
    return await this.send(this.buildUrl("songs"), "DELETE",{idsong})
  }

  /**
   * MediaLibraryAPI:searchSongs
   */ 
  async searchSongs({name, tags, status, scope})
  {
    let params = {
      tags: tags.map(tag => tag.tag).join(","),
      name: name,
      status: status,
      scope: scope
    }
    let data = await this.send(this.buildUrl("songs", params), "GET") 
    return data;
  }

  /**
   * MediaLibraryAPI:getSongs
   * return songs based on array of songs ids
   */ 
  async getSongs(ids)
  {
    let params = {
      ids: ids.join(",")
    }
    let data = await this.send(this.buildUrl("songs", params), "GET") 
    return data;
  }

  /**
   * MediaLibraryAPI:getSong
   * return song based on song id
   */ 
  async getSong(id)
  {
    let params = {
      id: id
    }
    let data = await this.send(this.buildUrl("songs", params), "GET") 
    return data
  }


  /**
   * MediaLibraryAPI:createTrack
   */ 
  async createTrack({idsong, name, data})
  {
    return await this.send(this.buildUrl("tracks"), "POST", {idsong, name, data})
  }


  /**
   * MediaLibraryAPI:updateTrack
   */ 
  async updateTrack({idsong, idtrack, data})
  {
    return await this.send(this.buildUrl("tracks"), "PUT", {idsong, idtrack, data})
  }

  /**
   * MediaLibraryAPI:deleteTrack
   */ 
  async deleteTrack(idsong, idtrack)
  {
    return await this.send(this.buildUrl("tracks"), "DELETE", {idsong, idtrack})
  }


  /**
   * MediaLibraryAPI:updateRemote
   * register a remote session with given channel name
   * operation: open or close
   * channel is required only for open ops
   */ 
  async updateRemote(op, channel)
  {
    return await this.send(this.buildUrl("remote"), "PUT", {op, channel: channel?channel:null});
  }

  /**
   * MediaLibraryAPI:getRemote
   * return remote session info
   * requires a guest_code 
   */ 
  async getRemote(guest_code)
  {
    let params = {
      guest_code: guest_code
    };
    let data = await this.send(this.buildUrl("remote", params), "GET");
    return data;
  }


  /**
   * MediaLibraryAPI:createPack
   * will create a pack in "draft" status
   */ 
  async createPack({name, author, description, config, tags})
  {
    return await this.send(this.buildUrl("packs"), "POST", {name, author, description, config, tags});
  }


  /**
   * MediaLibraryAPI:updatePack
   */ 
  async updatePack({idpack, name, author, description, status, config, tags})
  {
    return await this.send(this.buildUrl("packs"), "PUT", {idpack, name, author, description, status, config, tags})
  }

  /**
   * MediaLibraryAPI:deletePack
   */ 
  async deletePack(idpack)
  {
    return await this.send(this.buildUrl("packs"), "DELETE",{idpack})
  }

  /**
   * MediaLibraryAPI:searchPacks
   */ 
  async searchPacks({name, tags, status, scope})
  {
    let params = {
      tags: tags.map(tag => tag.tag).join(","),
      name: name,
      status: status,
      scope: scope
    }
    let data = await this.send(this.buildUrl("packs", params), "GET") 
    return data;
  }

  /**
   * MediaLibraryAPI:getPacks
   * return packs based on array of packs ids.
   * if ids is empty returns inmediatly empty array too.
   */ 
  async getPacks(ids) {
    if(!ids.length){
      return [];
    }
    let params = {
      ids: ids.join(",")
    }
    let data = await this.send(this.buildUrl("packs", params), "GET") 
    return data;
  }

  /**
   * MediaLibraryAPI:getPack
   * @param id mandatory id of the pack
   * @param version optional. defaults to 'latest'
   * @returns pack based on pack id
   */ 
  async getPack(id, version) {
    let params = {
      id,
      version
    };
    let data = await this.send(this.buildUrl("packs", params), "GET");
    return data;
  }
  
  /**
   * MediaLibraryAPI:getPackVersions
   * return all the versions of a pack.
   * we requiere the orgid to force the user to know the pack better...
   */ 
  async getPackVersions(orgid, idpack) {
    let params = {
      vid: idpack,
      orgid
    };
    let data = await this.send(this.buildUrl("packs", params), "GET");
    return data;
  }


  /**
   * MediaLibraryAPI:saveCardPic
   * we reuse the old cards/PUT endpoint of FlashCards v1.0,
   * to only store pics. 
  */ 
  async saveCardPic({idpack, iddeck, idcard, picpath, data}){
    return await this.send(this.buildUrl("cards"), "PUT", {op: 'default', idpack, iddeck, idcard, picpath, data});
  }
  
  /**
   * MediaLibraryAPI:clonePackCardsPics
   * given a idpacksrc, assumming it has the same structure of idpack, 
   * iterate over all cards and try to create copies of the pics folder in s3
   * (expected usage: after cloning a pack)
  */ 
  async clonePackCardsPics({idpack, idpacksrc, orgsrc, usersrc}){
    return await this.send(this.buildUrl("cards"), "PUT", {op: 'clonepack', idpack, idpacksrc, orgsrc, usersrc});
  }

  /**
   * MediaLibraryAPI:deletePackCardsPics
   * deletes all the cards pics of the given pack
  */ 
  async deletePackCardsPics({idpack}){
    return await this.send(this.buildUrl("cards"), "DELETE", {op: 'deleteall', idpack});
  }

  /**
   * MediaLibraryAPI:saveTargetPic
   * we reuse the old targets/PUT endpoint of FlashCards v1.0,
   * to only store pics. 
  */ 
  async saveTargetPic({idpack, idtarget, picpath, data}){
    return await this.send(this.buildUrl("targets"), "PUT", {op: 'default', idpack, idtarget, picpath, data});
  }


  /**
   * MediaLibraryAPI:saveWheelPic
  */ 
  async saveWheelPic({idpack, idwheel, picpath, data}){
    return await this.send(this.buildUrl("targets"), "PUT", {op: 'wheel', idpack, idwheel, picpath, data});
  }
  
  /*
   * MediaLibraryAPI:clonePackTargetsPics
   * given a idpacksrc, assumming it has the same structure of idpack, 
   * iterate over all targets and try to create copies of the pics folder in s3
   * (expected usage: after cloning a pack)
  */
  async clonePackTargetsPics({idpack, idpacksrc, orgsrc, usersrc}){
    return await this.send(this.buildUrl("targets"), "PUT", {op: 'clonepack', idpack, idpacksrc, orgsrc, usersrc});
  }

  /**
   * MediaLibraryAPI:deletePackTargetsPics
   * deletes all the targets pics of the given pack
  */ 
  async deletePackTargetsPics({idpack}){
    return await this.send(this.buildUrl("targets"), "DELETE", {op: 'deleteall', idpack});
  }

  /**
   * MediaLibraryAPI:updateWhitelist
   * operation: 'grant' | 'revoke'
   * target: 'pack', 'activity'
   * idtarget: either idpack or idactivity
  */ 
  async updateWhitelist({operation, target, idtarget, organization}){
    if(operation !== 'grant' && operation !== 'revoke'){
      throw new Error(`MediaLibraryAPI: updateWhitelist: unexpected operation: ${operation} `, 422);
    }
    if(target !== 'pack' && target !== 'activity'){
      throw new Error(`MediaLibraryAPI: updateWhitelist: unexpected target: ${target} `, 422);
    }
    if(!idtarget){
      throw new Error(`MediaLibraryAPI: updateWhitelist: expected idtarget`, 422);
    }
    if(!organization){
      throw new Error(`MediaLibraryAPI: updateWhitelist: expected organization`, 422);
    }
    let data = {
      organization,
      operation,
      metadata: {}
    };
    if(target === 'pack'){
      data.metadata.pack = idtarget;
    }else if(target === 'activity'){
      data.metadata.activity = idtarget;
    }
    return await this.send(this.buildUrl('whitelists'), 'POST', data);
  }


  /**
   * MediaLibraryAPI:getClients
   * return UnitusTI clients mapped to MediaLib clients.
   * requires to be logged in. 
   * see @getUnitusClientList for non-auth alternative
  */ 
  async getClients(usercode, pluginkey){
    if(!usercode){
      throw new Error(`MediaLibraryAPI: getClients: expected usercode`, 422);
    }
    if(!pluginkey){
      throw new Error(`MediaLibraryAPI: getClients: expected pluginkey`, 422);
    }
    return await this.send(this.buildUrl('clients', {usercode, pluginkey}), 'GET');    
  }

  /**
   * MediaLibraryAPI:getUnitusClientsList
   * this is a direct connection to unitusTI endpoint.
   * this one can be used without login, but won't return userids for the clients, 
   * only clientcodes.
  */ 
  async getUnitusClientsList(usercode, pluginkey){
    if(!usercode){
      throw new Error(`MediaLibraryAPI: getUnitusClientsList: expected usercode`, 422);
    }
    if(!pluginkey){
      throw new Error(`MediaLibraryAPI: getUnitusClientsList: expected pluginkey`, 422);
    }
    return await this.send(this.buildUrl('unitusti/clients/list'), 'GET', null, {usercode, pluginkey});    
  }

  /**
   * MediaLibraryAPI:getUnitusProgramsList
  */ 
  async getUnitusProgramsList(usercode, pluginkey) {
    if(!usercode){
      throw new Error(`MediaLibraryAPI: getUnitusClientsList: expected usercode`, 422);
    }
    if(!pluginkey){
      throw new Error(`MediaLibraryAPI: getUnitusClientsList: expected pluginkey`, 422);
    }
    return await this.send(this.buildUrl('unitusti/program/list'), 'GET', null, {usercode, pluginkey});    
  }


  /**
   * MediaLibraryAPI:getUnitusProgramDetails
  */ 
  async getUnitusProgramDetails(idprogram, usercode, pluginkey) {
    console.log("MOCKED ENDPOINT: getUnitusProgramsList: ");
    return new Promise((resolve, reject)=>{ 
      resolve({
        idprogram: idprogram, 
        name: `Program ${idprogram}`, 
        description: `the program ${idprogram}`,
        target_type: 'NUMERIC',
        targets: [
          {idtarget: idprogram*10 + 1, name: "target 1"},
          {idtarget: idprogram*10 + 2, name: "target 2"},
          {idtarget: idprogram*10 + 3, name: "target 3"},
          {idtarget: idprogram*10 + 4, name: "target 4"},
          {idtarget: idprogram*10 + 5, name: "target 5"},
        ]});
    });
  }

  /**
   * MediaLibraryAPI:getUserStage
   * retrieves custom storage per client-material
  */ 
  async getUserStorage({idclient, material}){
    if(!idclient){
      throw new Error(`MediaLibraryAPI: getUserStorage: expected idclient`, 422);
    }
    if(!material){
      throw new Error(`MediaLibraryAPI: getUserStorage: expected material`, 422);
    }
    return await this.send(this.buildUrl('clients/storage', {idclient, material}), 'GET');    
  }

  /**
   * updateUserStorage
   * saves custom storage per client-material
  */
  async updateUserStorage({idclient, material, metadata}){
    if(!idclient){
      throw new Error(`MediaLibraryAPI: getUserStorage: expected idclient`, 422);
    }
    if(!material){
      throw new Error(`MediaLibraryAPI: getUserStorage: expected material`, 422);
    }
    if(!metadata){
      throw new Error(`MediaLibraryAPI: getUserStorage: expected metadata`, 422);
    }
    return await this.send(this.buildUrl('clients/storage'), 'PUT', {idclient, material, metadata});    
  }

  /**
   * createExecution
   * stores custom execution per user-client-material
  */
  async createExecution({executionid, usercode, clientcode, material, data}){
    if (!executionid) {
      throw new Error(`MediaLibraryAPI: createExecution: expected executionid`, 422);
    }
    if(!clientcode){
      throw new Error(`MediaLibraryAPI: createExecution: expected clientcode`, 422);
    }
    if(!usercode){
      throw new Error(`MediaLibraryAPI: createExecution: expected usercode`, 422);
    }
    if(!material){
      throw new Error(`MediaLibraryAPI: createExecution: expected material`, 422);
    }
    if(!data){
      throw new Error(`MediaLibraryAPI: createExecution: expected data`, 422);
    }
    return await this.send(this.buildUrl('executions'), 'POST', {op: 'execution:create', executionid, usercode, clientcode, material, data});    
  }

  /** createVMActivityExecution
   * stores executions associated to vmactivities.
  */ 
  async createVMActivityExecution({idmember, idactivity, data}) {
    if (!idmember) {
      throw new Error(`MediaLibraryAPI: createExecution: expected idmember`, 422);
    }
    if(!idactivity){
      throw new Error(`MediaLibraryAPI: createExecution: expected idactivity`, 422);
    }
    if(!data){
      throw new Error(`MediaLibraryAPI: createExecution: expected data`, 422);
    }
    return await this.send(this.buildUrl('executions'), 'POST', {op: 'vmactivityexec:create', idmember, idactivity, data});    
  }

  /**
   * getExecution
   * get single custom execution per user-client-material
  */
  async getExecution({executionid, usercode, clientcode, material}){
    if (!executionid) {
      throw new Error(`MediaLibraryAPI: createExecution: expected executionid`, 422);
    }
    if(!clientcode){
      throw new Error(`MediaLibraryAPI: createExecution: expected clientcode`, 422);
    }
    if(!usercode){
      throw new Error(`MediaLibraryAPI: createExecution: expected usercode`, 422);
    }
    if(!material){
      throw new Error(`MediaLibraryAPI: createExecution: expected material`, 422);
    }
    return await this.send(this.buildUrl('executions', {executionid, usercode, clientcode, material, q:'detail'}), 'GET');    
  }

  /**
   * getExecutionSummary
   * options:
   * q: mandatory: summary_userid, summary_clientid, summary_clientids 
   * for summary_userid:
   *   dateini: yyyy-mm-dd
   *   dateend: yyyy-mm-dd
   * for summary_clientid: 
   *   clientid:  filter by specific client id
   * for summary_clientids:
   *   dateini: yyyy-mm-dd
   *   dateend: yyyy-mm-dd
   *   clientids:  comma separated array of clientcodes
   */ 
  async getExecutionSummary(options) {
    if(!options.q) {
      throw new Error(`MediaLibraryAPI: getExecutionSummary: expected query`, 422);
    }
    if(!['summary_userid', 'summary_clientids', 'summary_clientid'].includes(options.q)) {
      throw new Error(`MediaLibraryAPI: getExecutionSummary: expected valid query`, 422);
    }
    if(['summary_userid', 'summary_userids'].includes(options.q) && (!options.dateini || !options.dateend)){
      throw new Error(`MediaLibraryAPI: getExecutionSummary: expected valid date range`, 422);
    }
    if(options.q === 'summary_clientid' && (!options.clientid)){
      throw new Error(`MediaLibraryAPI: getExecutionSummary: expected valid clientid`, 422);
    }
    if(options.q === 'summary_clientids' && (!options.clientids)){
      throw new Error(`MediaLibraryAPI: getExecutionSummary: expected valid clientids`, 422);
    }
    return await this.send(this.buildUrl('executions', options), 'GET');
  }

  /**
   * getExecutions
   * options:
   * q: mandatory: summary_userid, 
   * for summary_userid:
   * dateini: yyyy-mm-dd
   * dateend: yyyy-mm-dd
   */ 
  async getExecutions(options) {
    if(!options.material) {
      throw new Error(`MediaLibraryAPI: getExecutions: expected material`, 422);
    }
    if(!options.executionids) {
      throw new Error(`MediaLibraryAPI: getExecutions: expected executionids`, 422);
    }
    if(!options.q) {
      throw new Error(`MediaLibraryAPI: getExecutions: expected query`, 422);
    }
    if(!['group'].includes(options.q)) {
      throw new Error(`MediaLibraryAPI: getExecutions: expected valid query`, 422);
    }
    return await this.send(this.buildUrl('executions', options), 'GET');
  }

  /**
   *
  */ 
  async createLock({resource, idresource}) {
    if (!resource) {
      throw new Error(`MediaLibraryAPI: createLock: expected resource`, 422);
    }
    if (!idresource) {
      throw new Error(`MediaLibraryAPI: createLock: expected idresource`, 422);
    }
    return await this.send(this.buildUrl('locks'), 'POST', {resource, idresource});    
  }

  /**
  */ 
  async deleteLock({resource, idresource}) {
    if (!resource) {
      throw new Error(`MediaLibraryAPI: deleteLock: expected resource`, 422);
    }
    if (!idresource) {
      throw new Error(`MediaLibraryAPI: deleteLock: expected idresource`, 422);
    }
    return await this.send(this.buildUrl('locks'), 'DELETE', {resource, idresource});    
  }

  /**
  */
  async createOp(params){
    return await this.send(this.buildUrl('ops'), 'POST', params);
  }

  /**
  */ 
  async publishPack({orgid, idpack, version}){
    return await this.createOp({op: 'packs:version:publish', orgid, idpack, version});
  }

  /**
  */
  async createPackVersion({orgid, idpack, version, new_version}){
    return await this.createOp({op: 'packs:version:new', orgid, idpack, version, new_version });
  }

  /**
  */
  async deletePackVersion({orgid, idpack, version}){
    return await this.createOp({op: 'packs:version:delete', orgid, idpack, version });
  }


  /**
   * MediaLibraryAPI:createMapping
   * will create a mapping in "draft" status
   */ 
  async createMapping({name, description, idpack, idprogram}) {
    return await this.send(this.buildUrl("mappings"), "POST", {name, description, idpack, idprogram});
  }


  /**
   * MediaLibraryAPI:updateMapping
   */ 
  async updateMapping({idmapping, idpack, idprogram, name, author, description, status, config, tags}) {
    return await this.send(this.buildUrl("mappings"), "PUT", {idmapping, idpack, idprogram, name, author, description, status, config, tags})
  }

  /**
   * MediaLibraryAPI:deleteMapping
   */ 
  async deleteMapping(idmapping) {
    return await this.send(this.buildUrl("mappings"), "DELETE",{idmapping})
  }

  /**
   * MediaLibraryAPI:searchMappings
   */ 
  async searchMappings({name, tags, status, scope}) {
    let params = {
      tags: tags.map(tag => tag.tag).join(","),
      name: name,
      status: status,
      scope: scope
    }
    let data = await this.send(this.buildUrl("mappings", params), "GET") 
    return data;
  }

  /**
   * MediaLibraryAPI:getMappings
   * return mappings based on array of mappings ids.
   * if ids is empty returns inmediatly empty array too.
   */ 
  async getMappings(ids) {
    if(!ids.length){
      return [];
    }
    let params = {
      ids: ids.join(",")
    }
    let data = await this.send(this.buildUrl("mappings", params), "GET") 
    return data;
  }

  /**
   * MediaLibraryAPI:getMapping
   * return mapping based on mapping id
   */ 
  async getMapping(id) {
    let params = {
      id: id
    };
    let data = await this.send(this.buildUrl("mappings", params), "GET");
    return data;
  }

  /**
  */ 
  async publishMapping({orgid, idmapping, version}) {
    return await this.createOp({op: 'mappings:version:publish', orgid, idmapping, version});
  }

  /**
  */
  async createMappingVersion({orgid, idmapping, version, new_version}) {
    return await this.createOp({op: 'mappings:version:new', orgid, idmapping, version, new_version });
  }

  /**
  */
  async deleteMappingVersion({orgid, idmapping, version}) {
    return await this.createOp({op: 'mappings:version:delete', orgid, idmapping, version });
  }

  // GROUPS

  /**
   * MediaLibraryAPI:createGroup
   * whose owner is the calleer.
   */ 
  async createGroup({name, description}) {
    return await this.send(this.buildUrl("groups"), "POST", {
      op:'groups:create', 
      name,
      description,
    });
  }


  /**
   * MediaLibraryAPI:updateGroup
   */ 
  async updateGroup({idgroup, name, description}) {
    return await this.send(this.buildUrl("groups"), "POST", {
      op: 'groups:update',
      idgroup, 
      name, 
      description,
      })
  }


  /**
   * MediaLibraryAPI:addMemberToGroup
   */ 
  async addMemberToGroup({idgroup, userid}) {
    return await this.send(this.buildUrl("groups"), "POST", {
      op: 'groups:members:add',
      idgroup,
      userid
    })
  }

  /**
   * MediaLibraryAPI:removeMemberFromGroup
   */ 
  async removeMemberFromGroup({idgroup, userid}) {
    return await this.send(this.buildUrl("groups"), "POST", {
      op: 'groups:members:remove',
      idgroup,
      userid
    })
  }

  /**
   * MediaLibraryAPI:deleteGroup
   */ 
  async deleteGroup(idgroup) {
    return await this.send(this.buildUrl("groups"), "POST",{
      op: 'groups:remove',
      idgroup
    })
  }

  /**
   * MediaLibraryAPI:searchGroups
   */ 
  async searchGroups({name}) {
    let params = {
      op: 'groups:get',
      q: 'search',
      name: name,
    }
    let data = await this.send(this.buildUrl("groups", params), "GET");
    return data;
  }

  /**
   * MediaLibraryAPI:getGroup
   * return group based on group id
   */ 
  async getGroup(idgroup) {
    let params = {
      op: 'groups:get',
      q: 'detail',
      idgroup
    };
    let data = await this.send(this.buildUrl("groups", params), "GET");
    return data;
  }

  // VMACTIVITIES

  /**
   * MediaLibraryAPI:createVMActivity
   * whose owner is the calleer.
   */ 
  async createVMActivity({name, description, idpack, idgroup, idmember, status}) {
    return await this.send(this.buildUrl("vmactivities"), "POST", {
      op:'vmactivities:create', 
      name,
      description,
      idpack,
      status,
      idgroup,
      idmember,
    });
  }


  /**
   * MediaLibraryAPI:updateVMActivity
   */ 
  async updateVMActivity({idactivity, name, description, idpack, status, idgroup, idmember}) {
    return await this.send(this.buildUrl("vmactivities"), "POST", {
      op: 'vmactivities:update',
      idactivity, 
      name, 
      description,
      idpack,
      idgroup, 
      idmember,
      status,
      })
  }

  /**
   * MediaLibraryAPI:deleteVMActivity
   */ 
  async deleteVMActivity(idactivity) {
    return await this.send(this.buildUrl("vmactivities"), "POST",{
      op: 'vmactivities:remove',
      idactivity
    })
  }

  /**
   * MediaLibraryAPI: searchAssignations
  */ 
  async searchAssignations() {
    let params = {
      op: 'vmactivities:get',
      q: 'assignments',
    }
    let data = await this.send(this.buildUrl("vmactivities", params), "GET");
    return data;
  }

  /**
   * MediaLibraryAPI:searchVMActivities
   */ 
  async searchVMActivities(params) {
    let query = {
      op: 'vmactivities:get',
      q: 'search',
      name: params.name,
      status: params.status,
      idmember: params.idmember,
      idgroup: params.idgroup,
      idpack: params.idpack
    }
    let data = await this.send(this.buildUrl("vmactivities", query), "GET");
    return data;
  }

  /**
   * MediaLibraryAPI:getVMActivity
   * return vmActivities based on vmActivities id
   */ 
  async getVMActivity(idactivity) {
    let params = {
      op: 'vmactivities:get',
      q: 'detail',
      idactivity
    };
    let data = await this.send(this.buildUrl("vmactivities", params), "GET");
    return data;
  }

}

/**
 * TagsService
 * keeps the list of tags and offer 
 * helper methods add and delete them.
 */
Mundopato.Medialibrary.TagsService = class {

/**
 * TagsService:constructor
 * @param api {Mundopato.Medialibrary.API}
 */
  constructor(api)
  {
    this.MAX_TAGS = 100
    this.api = api
    this.tags = null
  }

  /**
   * TagsService:init
   * load the list of targets
   */ 
  async init()
  {
    if(!this.tags)
    {
      let result = await this.api.getTags(null)
      //expected { tags: [ tags_objects ] }
      this.tags = result.tags
    }
    return this.tags
  }

  /**
   * TagsService:get
   * returns the tag object that matches the given name.
   * doesn't hit the backend!
   * if no present, returns null.
   */ 
  get(tag_name)
  {
    return this.tags.some(tob => tob.tag === tag_name); 
  }

  /**
   * to avoid hitting the api when creating tags,
   * just keep in sync the cache by pushing the new tag
   */ 
  push(tag_name)
  {
    if(!this.get(tag_name))
    {
      this.tags.push({tag: tag_name})
    }
  }


  /**
   * TagsService:filter
   * return array with filtered tags. may be empty
   */ 
  filter(filter)
  {
    let count = 0; 
    let filtered = [];
    let limit = Math.min(this.tags.length, this.MAX_TAGS);

    if(!filter)
    {
      for(let i=0; i<limit; ++i)
      {
        filtered.push(this.tags[i]);
      }
    }
    else
    {
      for(let i=0; i<this.tags.length; ++i)
      {
        var tag = this.tags[i];
        if(tag.tag.startsWith(filter))
        {
          filtered.push(tag)
          if(count++ > limit) 
            break;
        }
      };
    }
    return filtered;
  };

  /**
   * TagsService:unload
   */ 
  unload()
  {
    this.api = null;
    this.tags = null;
  }

}

module.exports = Mundopato; 
