import React, { useEffect, useState } from 'react';
import { Row, Col} from 'antd';

export default function Home() {
  return (
      <Row>
        <Col span='1'></Col>
        <Col  span='22'>
          <h1>Virtual Materials App</h1>
          <p className="text-muted">Welcome to Virtual Materials App!</p>
         </Col>
        <Col span='1'></Col>
      </Row>

  );
}
