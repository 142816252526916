/**
 * this is manually-generated, not using the amplify cli but
 * the output from the cdk subproject.
 */ 
const config = {
  Auth: {
    region: "us-west-2",
    userPoolId: "us-west-2_rYSuWWvlk",
    userPoolWebClientId: "3a05g0nmaf1hi7jamsr2b06r31"
  },
}

export default config;
