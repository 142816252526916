import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col} from 'antd';
import {Card, Input, Button, message } from 'antd';
import { useAppContext } from "../libs/contextLib";
import * as security from '../Security';

export default function Link() {
  const history = useHistory();
  const { user } = useAppContext();
  const [clientCode, setClientCode] = useState(null);

  function onClientCodeChanged(ev){
    setClientCode(ev.target.value);
  }

  async function onLink() {
    try {
      console.log("on link");
      console.log(clientCode);
      const medialib = security.getMediaLibrary();
      await medialib.createOp({
        op: 'vmapp:link',
        code: clientCode,
        sub: user.attributes.sub,
      });
      message.info('Account Linked!');
      history.push('/assignments');
    } catch(err) {
      console.error(err);
      if (err.code === 404) {
        message.error('Invalid code. Please try again.');
      }
    }
  }

  return (
      <Row>
        <Col span='1'></Col>
        <Col  span='22'>
      <Card>
        <h2>Link to UnitusTI</h2>
          <p>Your account is not linked to UnitusTI yet.</p>
          <p>Please enter your UnitusTI Client Code and press the 'Link' button:</p>
          <Input 
            type='text'
            placeholder='UnitusTI Client Code'
            onChange={onClientCodeChanged}
            >
          </Input>
          <Button 
            type='primary' 
            htmlType='button'
            onClick={onLink}
            >
            Link
          </Button>
      </Card>
         </Col>
        <Col span='1'></Col>
      </Row>

  );
}
