import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Router } from 'react-router-dom';
import Amplify from "aws-amplify";
import App from './App';
import reportWebVitals from './reportWebVitals';
import MyAwsExports from "./my-aws-exports";
import './index.css';

//initialize amplify..
Amplify.configure(MyAwsExports);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
