import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
// import { useParams } from 'react-router-dom';
// import { Row, Col} from 'antd';
import * as security from '../Security';
import MaterialContainer from './MaterialContainer';

export default function Assignment(props) {
  const history = useHistory();
  // const { params } = useParams();
  const [assignment, setAssignment] = useState(null);
  
  useEffect(() => {
    launchAssignment();
  }, []);

  /**
  */ 
  async function launchAssignment() {
    // what is the id of the assignment? 
    console.log("launch Assignment:");
    // const idactivity = +history.location.pathname.replace("/assignment/", "");
    // console.log(idactivity);
    const activity = security.getCurrentActivity();
    console.log(activity);
    setAssignment(activity);
  }

    if(assignment) {
      return (
       <MaterialContainer assignment={assignment} ></MaterialContainer>
      );
    } else {
      return (
      <div>loading..</div>
      );
    }
}
