import React, { useEffect, useState, useRef } from 'react';
import { Card } from 'antd';
import { Auth } from 'aws-amplify';
import * as security from '../Security';
import { useHistory } from "react-router-dom";

import './MaterialContainer.css';

function MaterialContainer({assignment}) {
  const [user, setUser] = useState(null);
  const history = useHistory();
  const mount = useRef(null);
  let isMounted = true;

  useEffect(() => {
    checkUser();
    return () => {
      isMounted = false;
    }
  }, []);

  /**
   * notifications from the vm
  */ 
  function onVMMessage(ev) {
    console.log("MaterialContanier.js: onVMMessage:");
    console.log(ev);
    if(ev.type === 'session.closed') {
      history.push('/assignments');   
    }
  }

  /**
  */ 
  async function checkUser() {
    const user = await Auth.currentAuthenticatedUser();
    if(!isMounted) return;
    setUser(user);
    if(user) {
      const success = await security.login();
      if(success) {
        console.log("assignment is");
        console.log(assignment);
        await security.initVM(mount.current, assignment, (ev) => onVMMessage(ev));
      }
    }
  }

  return(
    <div id='MaterialContainerDiv'  className='mp_mt_ml_container' ref={mount}>
      <div className= 'mp_mt_ml_container'>
        Loading</div>
    </div>
  )
}

export default MaterialContainer;
