import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, message} from 'antd';
import { List, Typography, Divider } from 'antd';
import { useAppContext } from "../libs/contextLib";
import * as security from '../Security';

export default function Assignments() {
  const history = useHistory();
  const { user } = useAppContext();
  const [assignments, setAssignments] = useState([]);
  
  useEffect(() => {
    getAssignments();
  }, []);

  /**
   *
   */ 
  async function getAssignments() {
    try {
      console.log('getting assignments..');
      message.loading({ content: 'Loading...', key: 'searchassignments' });
      let medialib = security.getMediaLibrary();
      if(!medialib) {
        await security.login();
        medialib = security.getMediaLibrary();
      }
      const assignments = await medialib.searchAssignations();
      setAssignments(assignments);
      message.destroy('searchassignments');
    } catch (error) {
        console.error(error);
    }
  }


  /**
   *
  */ 
  function launchAssignment(item){
    console.log("launch assignment:");
    console.log(item);
    security.setCurrentActivity(item);
    // history.push(`/assignment/${item.idactivity}`);
    history.push('/assignment');
  }

  return (
      <Row>
        <Col span='1'></Col>
        <Col  span='22'>
      <Card className='Assigments'>
        <h1>My Assignments</h1>
            <List
              bordered
              dataSource={assignments}
              renderItem={item => (
                <List.Item
                actions={[<a key={item.idactivity} onClick={() => launchAssignment(item)}>Launch</a>]}
                >
                    <List.Item.Meta
                      title={item.name}
                      description={item.pack_name}
                  />
                  <div>{item.description}</div>
                  </List.Item>
              )}
            />
      </Card>
         </Col>
        <Col span='1'></Col>
      </Row>
  );
}
